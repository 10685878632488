import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {Product} from '../model/Product';
import {Business} from '../model/Business';

@Component({
  selector: 'myafrica-product-info',
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.scss']
})
export class ProductInfoComponent implements OnInit, AfterViewInit {


  @Input() product: Product;
  @Input() productBusiness: Business;

  constructor() {
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    //  if (this.productBusiness)
    //   console.log('Details ', this.productBusiness);
  }
}
