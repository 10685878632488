import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpResponse} from '@angular/common/http';
import {RequestHeader} from '../RequestHeader';
import {Observable} from 'rxjs';


@Injectable({
    providedIn: 'root',
})
export class OrdersServiceService extends RequestHeader {

    headers = new HttpHeaders()
        .set('Content-Type', 'application/json');


    constructor(private http: HttpClient) {
        super();
    }

    getOrdersOutstandingCountByProfile(profileId: string) {
        return this.http.get(`myafrica-api/businessadmin/profile/${profileId}/getordersoutstandingcount`,
            {
                observe: 'response',
            });
    }


    getOrdersByProfile(profileId: string, conditionValue: string, periodValue: number) {
        return this.http.get(
            `myafrica-api/public/profile/${profileId}/${conditionValue}/${periodValue}/getordersbyprofile`,
            {headers: this.headers});
    }

    getOrdersByOrderNoByProfile(profileId: string, orderNo: string) {
        return this.http.get(`myafrica-api/public/profile/${profileId}/${orderNo}/getordersbyordernoprofile`,
            {headers: this.headers});
    }

    getOrdersByDateByStatus(conditionValue: string, periodValue: number) {
        return this.http.get(`myafrica-api/admin/orders/${conditionValue}/${periodValue}/getorders`,
            {headers: this.headers});
    }

    getOrderByDateByStatus(conditionValue: string, orderNo: string) {
        return this.http.get(`myafrica-api/admin/order/${conditionValue}/${orderNo}/getorder`,
            {headers: this.headers});
    }


    getOrdersByDates(date1: string, date2: string) {
        return this.http.get(`myafrica-api/public/orders/${date1}/${date2}/getordersbydates`,
            {headers: this.headers});
    }
	
	getOrdersByDatesByProfile(profileId: number, date1: string, date2: string) {
		return this.http.get(`myafrica-api/public/orders/${profileId}/${date1}/${date2}/getordersbydatesbyprofile`,
            {headers: this.headers});
	}


    getOrdersByDatesByStatus(date1: string, date2: string, status: string) {
        return this.http.get(`myafrica-api/admin/orders/${date1}/${date2}/${status}/getordersbydatesbystatus`
            , {headers: this.headers});
    }

    getOrderByStatus(orderNo: string, status: string) {
        return this.http.get(`myafrica-api/admin/order/${orderNo}/${status}/getorderbystatus`,
            {headers: this.headers});
    }


    getOrdersByOrderNo(orderNo: string) {
        return this.http.get('myafrica-api/customer/getorders/orderno/' + orderNo, {headers: this.headers});
    }

    getShopperOrders(emailAddress: string, itemsPerPage: number, pageNumber: number) {
        return this.http.get(`myafrica-api/orders/${emailAddress}/${itemsPerPage}/${pageNumber}/getordersbyemail`,
            {headers: this.headers});
    }

   getPaginationOrders(emailAddress: string, itemsPerPage: number, pageNumber: number, orderNo: string, date1: string, date2: string) {
        return this.http.get(`myafrica-api/orders/${emailAddress}/${itemsPerPage}/${pageNumber}/${orderNo}/${date1}/${date2}/getordersbyemail`,
            {headers: this.headers});
    }

	getPaginationInvoiceByDates(profileId: number, itemsPerPage: number, pageNumber: number, date1: string, date2: string) {
        return this.http.get(`myafrica-api/invoices/${profileId}/${itemsPerPage}/${pageNumber}/${date1}/${date2}/getinvoicesbyprofilebydates`,
            {headers: this.headers});
    }
	
	 getPaginationOrdersByInvoiceNo(profileId: number, itemsPerPage: number, pageNumber: number, invoiceNo: string) {
        return this.http.get(`myafrica-api/invoices/${profileId}/${itemsPerPage}/${pageNumber}/${invoiceNo}/getinvoicesbyprofilebyinvoicebydates`,
            {headers: this.headers});
    }


    getOrderItemStatuses() {
        return JSON.parse(sessionStorage.getItem('orderItemStatuses'));
    }
	
	getOrderStatusesByProfile(profileId: number) {
        return this.http.get('myafrica-api/orderadmin/profile/getorderstatuses/' +  profileId);
    }


    getOrderStatuses() {
        return JSON.parse(sessionStorage.getItem('orderStatuses'));
    }

    updateItemStatuses(profileId: string, statusesObject: any) {
        return this.http.post(`myafrica-api/orderadmin/${profileId}/updateitemstatuses`,
            statusesObject, {
                observe: 'response',
            });
    }


    updateOrdersStatuses(statusesObject: any) {
        return this.http.post('myafrica-api/orderadmin/updateordersstatuses',
            statusesObject, {
                observe: 'response',
            });
    }


    generateOrder(cart: any, totalAmount: number): Observable<any> {
        return this.http.post(`myafrica-api/order/customer/add/${totalAmount}`,
            cart, {observe: 'response'});

    }

    generateOrderReturn(orderReturn: any, profileId: number): Observable<HttpResponse<any>> {

        return this.http.post('myafrica-api/customer/order/return/add/' + profileId,
            orderReturn, {
                observe: 'response',
            });

    }

    getOrderReturnByRefNumber(refNumber: string) {
        return this.http.get('myafrica-api/customer/order/return/'
            + refNumber, {headers: this.headers});

    }

    getOrderReturnByOrderNumber(orderNumber: string) {
        return this.http.get('myafrica-api/customer/order/returns/'
            + orderNumber, {headers: this.headers});

    }

    getOrderReturnsByDates(date1: string, date2: string) {
        return this.http.get('myafrica-api/public/order/return/'
            + date1 + '/' + date2 + '/getorderreturnsbydates', {headers: this.headers});
    }
	
	 getOrderReturnsByProfileByDates(profileId: number, date1: string, date2: string) {
        return this.http.get('myafrica-api/public/order/return/'
            + profileId + '/'  + date1 + '/' + date2 + '/getorderreturnsbyprofilebydates', {headers: this.headers});
    }
	
	getOrderReturnsByProfileByRefNumber(profileId: number, refNumber: string) {
        return this.http.get('myafrica-api/public/order/return/'
            + profileId + '/' + refNumber + '/getorderreturnsbyprofilebyrefnumber', {headers: this.headers});
    }
	
	getOrderReturnsByProfileByOrderNumber(profileId: number, orderNumber: string) {
        return this.http.get('myafrica-api/public/order/return/'
            + profileId + '/' + orderNumber + '/getorderreturnsbyprofileorderno', {headers: this.headers});
    }

    getReturnStatuses() {
        return this.http.get('myafrica-api/orderadmin/getreturnstatuses');
    }


    updateReturnsStatuses(statusesObject: any) {
        return this.http.post('myafrica-api/orderadmin/updatereturnsstatuses',
            statusesObject, {
                observe: 'response',
            });
    }

    generateOrderInvoices(customerOrderInvoice: any) {
        return this.http.post('myafrica-api/customer/create/invoice',
            customerOrderInvoice, {
                observe: 'response',
            });
    }

    generateOrderInvoicesReport(customerOrderInvoice: any, orderNo: string) {
        return this.http.post(`myafrica-api/generate/invoice/report/${orderNo}`,
            customerOrderInvoice, {responseType: 'arraybuffer'});
    }

    /*
     *  Regenerate existing invoices for an order.
     */
    regenerateOrderInvoicesReport(customerOrderInvoice: any, orderNo: string) {
        return this.http.post(`myafrica-api/regenerate/invoice/report/${orderNo}`,
            customerOrderInvoice, {responseType: 'arraybuffer'});
    }
	
	/*
     *  Print ivncoice report.
     */
    printInvoiceReport(customerOrderInvoice: any) {
        return this.http.post(`myafrica-api/print/invoice/report`,
            customerOrderInvoice, {responseType: 'arraybuffer'});
    }


    updateOrderStatusToPaid(orderNo: string) {
        return this.http.post(`myafrica-api/order/update/paid/status/${orderNo}`,
            {observe: 'response'});

    }


    generateClientCollectionInvoice(customerOrder: any) {
        return this.http.post('myafrica-api/client/create/invoice',
            customerOrder, {
                responseType: 'arraybuffer',
            });
    }


    /*
     * Prints orders based on date range
     */
    printOrdersReport(date1: string, date2: string, profileId: number) {
        return this.http.post('myafrica-api/orders/' + date1 + '/' + date2 + '/' + profileId + '/printordersbydates', '', {
            responseType: 'arraybuffer',
        });
    }


    printOrdersByBusinessReport(date1: string, date2: string, businessId: number) {
        return this.http.post(`myafrica-api/orders/${date1}/${date2}/${businessId}/printordersbybusinessbydates`,
            '', {responseType: 'arraybuffer'});
    }
	
	  /*
     * Prints orders based on date range and profile
     */
    printOrdersReportByDatesByProfile(date1: string, date2: string, profileId: number) {
        return this.http.post('myafrica-api/orders/' + date1 + '/' + date2 + '/' + profileId + '/printordersbydatesbyprofileemail', '', {
            responseType: 'arraybuffer',
        });
    }
	
	 /*
     * Prints orders based on order number
     */
    printReturn(orderReturnResource: any) {
        return this.http.post('myafrica-api/return/printreturn', orderReturnResource, {
            responseType: 'arraybuffer',
        });
    }


 /*
     * Prints orders based on order number
     */
    printOrdersReportByOrderNo(orderNo: string, profileId: number) {
        return this.http.post('myafrica-api/orders/' + orderNo + '/' +profileId + '/printordersbyorderno', '', {
            responseType: 'arraybuffer',
        });
    }

    /**
     * Generate a time in the following format yyyyMMddHHMMSS
     *
     */
    generateDateTime() {
        const d = new Date();
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();
        const hourMMSS = '' + d.getHours() + d.getMinutes() + d.getSeconds();
        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        return '' + d.getHours() + year + d.getMinutes() + month + d.getSeconds() + day;

    }


    generateRandomString() {
        const STRING_LENGTH = 4;
        let text = '';
        const charList = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        for (let i = 0; i < STRING_LENGTH; i++) {
            text += charList.charAt(Math.floor(Math.random() * charList.length));
        }
        // MA=My Africa. Just to ensure that all orders starts with MA
        return 'MA' + text;
    }


}
