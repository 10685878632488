import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {BusinessBankingDetail} from '../model/BusinessBankingDetail';
import {Product} from '../model/Product';
import {Business} from '../model/Business';
import {PaginationProduct} from '../model/PaginationProduct';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class BusinessService {

    responseResult: any;

    userRole: string;

    constructor(private http: HttpClient) {
    }

    getAllBusinessDetails(businessId: string) {
        return this.http.get((environment.apiPrefix  + '/views/')
            .concat(`${businessId}/viewbusiness`), {observe: 'response'});
    }

    addBusiness(profileId: string, business: Business) {
        return this.http.post((environment.apiAdminPrefix  + '/add-business'),
            business,
            {observe: 'response'},
        );
    }

    updateBusiness(businessId: string, business: Business) {
        return this.http.post((environment.apiPrefix  + '/businessadmin/business/')
                .concat(`${businessId}`)
                .concat('/addbusinessaddress'),
            business,
            {observe: 'response'});
    }

    updateBusinessDetails(businessId: string, businessName: string, business: Business) {
        return this.http.post((environment.apiPrefix  + '/businessadmin/business/')
                .concat(`${businessId}`)
                .concat('/adddetails'),
            business,
            {observe: 'response'});
    }

    getBusinessesByProfileId(profileId: string) {
        return this.http.get((environment.apiAdminPrefix  + '/businessess/')
            .concat(`${profileId}`), {observe: 'response'});
    }

    updateBankingDetails(businessId: string, bankingDetails: BusinessBankingDetail) {


        return this.http.post((environment.apiPrefix  + '/businessadmin/business/')
                .concat(`${businessId}/addbusinessbankingdetails`),
            bankingDetails, {observe: 'response'});
    }

    getBusinessBankingDetails(businessId: number): Observable<any> {
        return this.http.get((environment.apiPrefix  + '/businessadmin/business/')
            .concat(`${businessId}`)
            .concat('/getbusinessbankingdetails'), {observe: 'response'});
    }

    getBusinessHours(businessId: string) {
        return this.http.get((environment.apiPrefix  + '/businessadmin/business/')
            .concat(`${businessId}/getbusinesshours`), {observe: 'response'});
    }

    updateBusinessHours(businessId: string, business: Business) {
        return this.http.post((environment.apiPrefix  + '/businessadmin/business/')
                .concat(`${businessId}/addbusinesshours`),
            business, {observe: 'response'});
    }


    getBusinessDetails(businessId: string) {
        return this.http.get((environment.apiPrefix  + '/businessadmin/business/')
            .concat(`${businessId}/getbusinessdetails`), {observe: 'response'});
    }

    getBusinessAddress(businessId: string) {
        return this.http.get((environment.apiPrefix  + '/businessadmin/business/')
            .concat(`${businessId}/getbusinessaddress`), {observe: 'response'});
    }

    getPaginationBusinessProductsByName(productName: string,
                                        itemsPerPage: number,
                                        pageNumber: number,
                                        productCategory: string,
                                        businessIds: string[]): Observable<PaginationProduct[]> | Observable<any> {
        return this.http.get((environment.apiPrefix  + '/product/allproducts/')
                .concat(`${productName}/${itemsPerPage}/`)
                .concat(`${pageNumber}/${productCategory}/`)
                .concat(`${businessIds}/getpaginationbusinesssproductsbyname`),
            {observe: 'response'});
    }


    getBusinessByNameAndProfile(businessName: string, profileId: number) {
        return this.http.get((environment.apiPrefix  + '/profile/allbusiness/')
            .concat(businessName)
            .concat(`/${profileId}`), {observe: 'response'});
    }


    // get a list of active businesses matching a partial or full business name for pagination
    getAdminPaginationBusinessByName(businessName: string,
                                     itemsPerPage: number,
                                     pageNumber: number,
                                     businessCategory: string, orderBy: string, filter: string): Observable<any> {
        filter = !filter || '' === filter ? 'undefined' : filter;
        return this.http.get((environment.apiPrefix  + '/allbusiness/')
            .concat(`${businessName}`)
            .concat(`/${itemsPerPage}/`)
            .concat(`${pageNumber}/${businessCategory}/${filter}/getallbusinessbyname`), {observe: 'response'});
    }

    isAdministrator() {
        this.userRole = sessionStorage.getItem('userRole');
        return this.userRole === 'Administrator' || this.userRole === 'Admin Clerk';
    }

    deleteProduct(businessId: number, productId: number) {
        return this.http.post((environment.apiPrefix  + '/businessadmin/business/')
                .concat(`${businessId}`)
                .concat('/product/delete/')
                .concat(`${productId}`),
            this.responseResult, {
                observe: 'response',
            });
    }


    undeleteProduct(businessId: number, productId: number) {
        return this.http.post(environment.apiPrefix  + '/businessadmin/business/'
                .concat(`${businessId}`)
                .concat('/product/undelete/')
                .concat(`${productId}`),
            this.responseResult, {
                observe: 'response',
            });
    }


    updateProduct(businessId: string, product: Product) {
        return this.http.post(environment.apiPrefix  + '/businessadmin/business/' + businessId + '/addproduct',
            product, {
                observe: 'response',
            });
    }


    uploadFile(file: File, businessId: string) {
        const formData: FormData = new FormData();
        formData.append('file', file);

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'image/*');
        headers.append('Accept', 'application/json');
        return this.http.post(environment.apiAdminPrefix  + '/businessadmin/business/'
                .concat(`${businessId}`)
                .concat('/addbusinesslogo'),
            formData, {observe: 'response', headers});


    }

    uploadBillboardFile(file: File, businessId: string) {
        const formData: FormData = new FormData();
        formData.append('file', file);

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'image/*');
        headers.append('Accept', 'application/json');
        return this.http.post(environment.apiAdminPrefix  + '/businessadmin/business/'
                .concat(`${businessId}`)
                .concat('/addbusinessbillboard'),
            formData, {observe: 'response', headers});


    }


    addProductWithImage(file: File, businessId: string, product: string) {
        const formData: FormData = new FormData();
        formData.append('file', file);
        formData.append('jsonProduct', product);

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'image/*');
        headers.append('Accept', 'application/json');
        return this.http.post(environment.apiPrefix  + '/businessadmin/business/'
                .concat(`${businessId}`)
                .concat('/addproductwithimage'),
            formData, {observe: 'response', headers});


    }


    addProductImage(file: File, productId: number, product: string) {
        const formData: FormData = new FormData();
        formData.append('file', file);
        formData.append('colour', product);

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'image/jpeg');
        headers.append('Accept', 'application/json');

        return this.http.post(environment.apiPrefix  + '/public/colours/${productId}/image',
            formData, {observe: 'response', headers});


    }

    addProductWithColours(file: File, businessId: string, product: string, colourFile: File, colourProduct: string) {
        const formData: FormData = new FormData();
        formData.append('file', file);
        formData.append('jsonProduct', product);

        formData.append('colourFile', file);
        formData.append('colourProduct', colourProduct);

        const headers = new HttpHeaders();
        headers.append('Content-Type', 'image/*');
        headers.append('Accept', 'application/json');
        return this.http.post(environment.apiPrefix  + '/businessadmin/business/'
                .concat(`${businessId}`)
                .concat('/addproductwithcolours'),
            formData, {observe: 'response', headers});


    }


    updateProductClothingColours(colourId: number, product: string) {
        return this.http.post(environment.apiPrefix  + '/public/colours/${colourId}/updatecolourcounts',
            product, {observe: 'response'});


    }
}
