import {Component, Input, OnInit} from '@angular/core';
import {Product} from '../model/Product';

@Component({
    selector: 'myafrica-out-of-stock',
    templateUrl: './out-of-stock.component.html',
    styleUrls: ['./out-of-stock.component.scss'],
})
export class OutOfStockComponent implements OnInit {
    
	stockCount: number;
	
    @Input() product: Product;

    constructor() {
    }

    ngOnInit() {
        this.showProductOutOfStock();
    }

    showProductOutOfStock(): boolean {	
		if (this.product.colours) {
            const clothingColours = this.product.colours;
			this.stockCount = 0;
			 clothingColours.forEach(color => {
				if(color.clothingSizes) {
					 color.clothingSizes.forEach(clothingSize => {
						if(clothingSize.count > 0) {
							this.stockCount = this.stockCount+1;
						}	
					 });
				}
	        });
            return this.stockCount === 0;
        }
        return this.product.unitsInStock <= 0;
    }
}
