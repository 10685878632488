import {Product} from './Product';

export class CartItem {

    product: Product;
    quantity: number;
    productSize: string;
    productColour: string;
    productSizeId: string;
    image: string;
    maxCount: number;
}
